import React, { useContext, useState, useCallback, useEffect, useRef } from 'react'
import styled, { ThemeContext } from 'styled-components'

// components
import ExchangePets from './ExchangePets'
import { ButtonConfirmed } from 'components/Button'
import Loader from 'components/Loader'
import BigNumber from 'bignumber.js'

// hooks
import { useActiveWeb3React } from 'hooks'
import { useAddPopup, useWalletModalToggle } from 'state/application/hooks'
// import useApproveLaunch from 'hooks/ido/useApproveLaunch'
import useAllowanceLaunch from 'hooks/useAllowance'

import { IDOTYPE } from 'constants/ido'
import { FnContext } from 'contexts/fnCallback'

import { getWeb3 } from 'utils/web3'
import Row, { AutoRow, RowBetween } from 'components/Row'
import {
  NETWORK_LABELS,
  NETWORKk_RPC_URL,
  NETWORKk_URL_MAP,
  TO_DEV_CHAINID,
  TO_MASTER_CHAINID,
  BAKERY_HOST_ORG,
  CONTRACT_ADDRESSES,
} from 'constants/pet'
import { StorageEmitter, formatTime, getAccountToken, localStore } from 'utils/tools'
import { useGetUserInfo, useLogin, useLoginTwitter } from 'hooks/useLogin'
import { TransactionReceipt } from 'web3-eth'
// import NumericalInput from 'components/NumericalInput'
// import { ReactComponent as Star } from 'assets/svg/star.svg'
import { useUpdateUserInfo, useUserInfo } from 'state/twitter/hooks'
import { useLocation } from 'react-router-dom'
import { bindSolanaAddress, fetchClaimAmount, fetchClaimParams, fetchUserSolanaAddress } from 'service/user'
import { useConnect } from 'hooks/useWallet'
import { useSolanaPublicKey, useUpdateSolanaPublicKey } from 'state/solana/hooks'
// import { ReactComponent as Copy } from 'assets/svg/copy.svg'
// import { ReactComponent as IAddress } from 'assets/svg/address.svg'
import useCopyClipboard from 'hooks/useCopyClipboard'
import TipIcon from 'assets/svg/whitelist_error.svg'
import TOKEN_ABI from 'utils/lib/abi/erc20.json'
import MIGRATE_ABI from 'utils/lib/abi/deposit.json'
import { parseUnits } from '@ethersproject/units'

import { Connection, PublicKey, SystemProgram, Transaction, ComputeBudgetProgram } from '@solana/web3.js'
import * as anchor from '@coral-xyz/anchor'
import { AnchorProvider, BN } from '@coral-xyz/anchor'
import { IDL } from 'utils/lib/idl/claim'
import { toArray } from 'utils'
import { getOrCreateAssociatedTokenAccount } from 'utils/getOrCreateAssociatedTokenAccount'
import { findClaimStatusKey } from 'utils/pda'
import { TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { fetchDogexTimeConfig } from 'service/launchpad'
import { Summary, BtnConfirmed } from './backup'
import Modal from 'components/Modal'
import { CloseColor } from 'components/AccountDetails'
import { ChainId } from 'constants/index'
import { useTokenBalance } from 'state/wallet'
import { isMobile } from 'react-device-detect'

const Exchange = styled.div`
  font-family: 'Roboto-Regular', 'Inter var', sans-serif;
  margin-top: 24px;
  padding-bottom: 28px;
  margin-bottom: 21px;
  border-bottom: 1px solid rgba(172, 85, 42, 0.1);
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-bottom: 21px;
  `}
`
const StepText = styled.div`
  font-family: 'Roboto-Bold';
  font-size: 14px;
  line-height: 16px;
  color: #ac552a;
  margin-bottom: 15px;
  display: flex;
  align-items: flex-end;
  svg {
    margin-right: 7px;
  }
`

const TwitterName = styled.div`
  font-family: 'Roboto-Bold';
  font-size: 14px;
  line-height: 16px;
  color: #ac552a;
  a {
    color: #ac552a;
  }
`

const BindWrapper = styled.div`
  background: ${({ theme }) => (theme.darkMode ? '#39302C' : '#fefaef')};
  width: 100%;
  padding: 18px 24px 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  border-radius: 10px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 18px 16px 24px;
  `}
`

const BindTitle = styled.div`
  color: ${({ theme }) => (theme.darkMode ? '#FFCD84' : '#ac552a')};
  font-family: 'Roboto-Bold';
  font-size: 14px;
  line-height: 119%;
  letter-spacing: 0.14px;
  margin-bottom: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 14px;
  `}
`

const BindInputWrapper = styled.div`
  width: 100%;
  border-radius: 11px;
  border: 1px solid rgba(172, 85, 42, 0.3);
  background: ${({ theme }) => (theme.darkMode ? 'transparent' : '#fff')};
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  grid-column-gap: 20px;
  input {
    flex: 1;
    color: ${({ theme }) => (theme.darkMode ? '#F8CE8E' : '#ac552a')};
    font-family: 'Roboto-Regular';
    font-size: 15px;
    letter-spacing: -0.15px;
    border: none;
    outline: none;
    height: 100%;
    background: transparent;
    ::placeholder {
      opacity: 0.3;
    }
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0 16px;
    margin-bottom: 12px;
  `}
`

const SubmitBtn = styled(ButtonConfirmed)`
  width: 120px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 11px;
  background: ${({ theme }) => (theme.darkMode ? '#FFCD84' : '#ac552a')};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => (theme.darkMode ? '#722F0D' : '#fff')};
  font-size: 18px;
  right: -1px;
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    font-size: 16px;
    right: 0;
  `}
`

const Line = styled.div`
  margin: 24px 0;
  width: 100%;
  height: 1px;
  background: rgba(172, 85, 42, 0.1);
`

const StepWrapper = styled.div`
  background: ${({ theme }) => (theme.darkMode ? '#39302C' : '#fefaef')};
  width: 100%;
  padding: 24px 24px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border-radius: 10px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 16px;
  `}
`

const Title = styled.div`
  font-family: 'Roboto-Medium';
  font-size: 14px;
  line-height: 168%;
  letter-spacing: 0.42px;
  margin-bottom: 10px;
  color: ${({ theme }) => (theme.darkMode ? '#F8CE8E' : '#ac552a')};
  span {
    color: #722f0d;
    font-family: 'Roboto-Bold';
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 12px;
  `}
`

const Address = styled.div`
  display: inline-flex;
  align-items: center;
  color: #722f0d;
  font-family: 'Roboto-Bold';
  font-size: 14px;
  line-height: 119%;
  margin-top: 3px;
  word-break: break-all;
  div {
    margin-left: 8px;
    display: inline-block;
    svg {
      cursor: pointer;
    }
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    line-height: 161%;
    svg {
      margin-bottom: -6px;
    }
  `}
`

const SolAddress = styled.div`
  color: #722f0d;
  font-family: 'Roboto-Medium';
  font-size: 14px;
  line-height: 133%;
  margin-left: 6px;
  margin-right: 10px;
`

const FollowBtn = styled.button`
  border: none;
  padding: 0;
  width: 101px;
  height: 32px;
  border-radius: 5px;
  background: #ac552a;
  font-family: Baloo Da;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
`

const Max = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  color: ${({ theme }) => theme.primary1};
  font-size: 14px;
  padding: 0;
  font-family: 'Roboto-Bold', 'Inter var', sans-serif;
  letter-spacing: 0.01em;
  margin-right: 13px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 15px;
  `}
`

const Data = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #c58560;
  word-break: break-all;
  span {
    font-family: 'Roboto-Bold';
    color: #ac552a;
  }
`

const Des = styled.div`
  font-family: 'Roboto-Regular', 'Inter var', sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.01em;
  white-space: pre-line;
  color: ${({ theme }) => theme.bsText38};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
  `}
`

const WalletBtn = styled(ButtonConfirmed)`
  width: 124px;
  height: 32px;
  padding: 0;
  border-radius: 6px;
  border: none;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => (theme.darkMode ? '#722F0D' : '#fff')};
  :disabled {
    opacity: 0.5;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 68px;
    height: 32px;
  `}
`

const ClaimNumber = styled.div`
  display: flex;
  align-items: center;
  color: #722f0d;
  color: ${({ theme }) => (theme.darkMode ? '#C58560' : '#722f0d')};
  font-family: 'Baloo Da';
  font-size: 20px;
  line-height: 119%;
  span {
    font-family: 'Roboto-Medium';
    color: #c58560;
    font-size: 12px;
    margin-left: 8px;
  }
`

interface IdoSupportProps {
  type: any
  options: any
  dataInfo: any
  defaultSymbol?: any
  platform?: string
}

const WalletList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 40px;
  background: #fff;
  padding: 18px;
  width: 100%;
`

const WalletWrapper = styled.div`
  background-color: #f7f8fa;
  border: 1px solid;
  border-radius: 12px;
  width: 100%;
  height: 58px;
  border-color: #eed9cc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding: 1rem;
  flex-shrink: 0;
  color: #722f0d;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  :hover {
    border-color: #ac562a;
    cursor: pointer;
  }
  img {
    width: 24px;
    height: 24px;
  }
`

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #722f0d;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  position: relative;
  margin-bottom: 10px;
  svg {
    position: absolute;
    right: 18px;
  }
`

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const programId = {
  devnet: new PublicKey('57MdA8RYcH6jWf4ZQnADECfXHPaoYnxVPtqiNTrTaSfN'),
  mainnet: new PublicKey('57MdA8RYcH6jWf4ZQnADECfXHPaoYnxVPtqiNTrTaSfN'),
}

const tokenAccount = {
  devnet: new PublicKey('6JPHYtFDXoovTwStwhcinDyWteB18GAABQnTv8bE5CaV'),
  mainnet: new PublicKey('GbroKgDk9PqkE7tTtmVWrt9qeDvimJCXqGTnpiKEryux'),
}

const network_url = {
  devnet: 'https://api.devnet.solana.com',
  mainnet: 'https://florri-gvcn41-fast-mainnet.helius-rpc.com',
}
export default function SupportCard({ type, options, dataInfo, defaultSymbol, platform }: IdoSupportProps) {
  const { account, chainId, library } = useActiveWeb3React()
  const addPopup = useAddPopup()
  const [, loginFn] = useLogin()
  const toggleWalletModal = useWalletModalToggle()
  const updateSolanaPublicKey = useUpdateSolanaPublicKey()
  const userInfo = useUserInfo()
  const theme = useContext(ThemeContext)
  const callback: any = useContext(FnContext)
  const [, getUserInfoFn] = useGetUserInfo()
  const query = useQuery()
  const [copied, setCopied] = useCopyClipboard()
  const updateUserInfo = useUpdateUserInfo()
  const solanaPublicKey = useSolanaPublicKey()

  // useEffect(() => {
  //   try {
  //     const status = query.get('status')
  //     if (status) {
  //       const msg: any = query.get('message')
  //       addPopup({
  //         txn: {
  //           hash: '',
  //           success: status === 'true' ? true : false,
  //           summary: status === 'true' ? 'Authorization complete' : msg ? msg : 'Authorization failed',
  //         },
  //       })
  //       window.location.href = window.location.origin + '/#/launchpad'
  //     }
  //   } catch (e) {
  //     console.log('Oauth feedback', e)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const [baseAsset, setBaseAsset] = useState(defaultSymbol ? defaultSymbol : options[0])
  const [value, setValue] = useState<string | number>('')

  const [isLogin, setIsLogin] = useState(false)

  // const oauthTwitterFn = useLoginTwitter()
  // const [isTwitterLoading, setIsTwitterLoading] = useState(false)
  // const handleTwitterAuth = useCallback(() => {
  //   if (!account) {
  //     toggleWalletModal()
  //     return
  //   }
  //   if (!isLogin) {
  //     loginFn()
  //     return
  //   }
  //   if (isTwitterLoading || userInfo?.twitterId) return
  //   setIsTwitterLoading(true)
  //   oauthTwitterFn()
  //   setIsTwitterLoading(false)
  // }, [account, isLogin, isTwitterLoading, userInfo, oauthTwitterFn, toggleWalletModal, loginFn])

  const allowanceLaunch = useAllowanceLaunch().div(new BigNumber(10).pow(18))?.toString()

  const updateValue = useCallback((value) => {
    setValue(value)
  }, [])

  const updateBaseAsset = useCallback(
    async (value: any) => {
      if (!account) {
        setBaseAsset(value)
        callback(value)
        updateValue('')
        return
      }
      if (
        (type === IDOTYPE.ONECAT &&
          ['bakery', 'eth', 'wbtc', 'doggycoin', 'punkx'].includes(value.symbol.toLowerCase())) ||
        ([
          IDOTYPE.BITLAND,
          IDOTYPE.BITCAT,
          IDOTYPE.BENDDAO,
          IDOTYPE.CATNFT,
          IDOTYPE.OPENSKY,
          IDOTYPE.BITCOINVM,
        ].includes(type) &&
          ['eth', 'wbtc', 'bakery', 'onecat'].includes(value.symbol.toLowerCase()))
      ) {
        const toChainId = BAKERY_HOST_ORG ? TO_MASTER_CHAINID : TO_DEV_CHAINID
        const ethereum: any = library?.provider
        // console.log('d.symbol', value.symbol)
        if (chainId === toChainId[value.symbol.toLowerCase()]) {
          setBaseAsset(value)
          callback(value)
          updateValue('')
          return
        }

        const web3 = getWeb3(toChainId[value.symbol.toLowerCase()])
        await ethereum
          .request({
            method: 'wallet_switchEthereumChain',
            params: [
              {
                chainId: web3.utils.toHex(toChainId[value.symbol.toLowerCase()]),
              },
            ],
          })
          .then(() => {
            setBaseAsset(value)
            callback(value)
            updateValue('')
            // window.location.reload()
          })
          .catch(async (e: any) => {
            if ((e as any).code === 4902 || (e as any).code === -32603) {
              ethereum
                .request({
                  method: 'wallet_addEthereumChain',
                  params: [
                    {
                      chainId: web3.utils.toHex(toChainId[value.symbol.toLowerCase()]),
                      chainName: NETWORK_LABELS[toChainId[value.symbol.toLowerCase()]],
                      nativeCurrency: {
                        name: 'ETH',
                        symbol: 'ETH',
                        decimals: 18,
                      },
                      rpcUrls: [NETWORKk_RPC_URL[toChainId[value.symbol.toLowerCase()]]],
                      blockExplorerUrls: [NETWORKk_URL_MAP[toChainId[value.symbol.toLowerCase()]]],
                    },
                  ],
                })
                .then(() => {
                  setBaseAsset(value)
                  callback(value)
                  updateValue('')
                  // window.location.reload()
                })
                .catch((e: any) => {
                  console.log(JSON.stringify(e))
                })
            } else if ((e as any).code === 4001) return
          })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [account, type, callback, updateValue, library?.provider, chainId]
  )

  const [leftTime, setLeftTime] = useState('')
  const [startTime, setStartTime] = useState(0)
  const [migrateEndTime, setMigrateEndTime] = useState(0)
  const [isMigrateEnd, setIsMigrateEnd] = useState(false)
  const [claimStartTime, setClaimStartTime] = useState(0)
  // const [isShowTip, setIsShowTip] = useState(false)
  // const [isWL, setIsWL] = useState(false)
  // const [nonWhitelistEndTime, setNonWhitelistEndTime] = useState(0)
  const handleFetchTime = useCallback(async () => {
    const res = await fetchDogexTimeConfig(chainId as number)
    if (res.code === 0 && res.data) {
      const { transform, claim } = res.data
      setIsMigrateEnd(transform?.timeEnd < Date.now())
      setStartTime(transform.timeOn)
      setMigrateEndTime(transform.timeOn)
      setClaimStartTime(claim.timeOn)
    }
  }, [chainId])

  const [solAddress, setSolAddress] = useState('')
  const [bindSolAddress, setBindSolAddress] = useState('')
  const handlefetchSolanaAddress = useCallback(async () => {
    const res = await fetchUserSolanaAddress(chainId as number, account as string)
    if (res.code === 0) {
      setSolAddress(res.data?.solAddress || '')
      setBindSolAddress(res.data?.solAddress || '')
      updateSolanaPublicKey(res.data?.solAddress || '')
    } else {
      setSolAddress('')
      setBindSolAddress(res.data?.solAddress || '')
    }
  }, [account, chainId, updateSolanaPublicKey])

  useEffect(() => {
    handleFetchTime()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [isNeedConnect, setIsNeedConnect] = useState(false)
  useEffect(() => {
    if (account) {
      handlefetchSolanaAddress()
    }
    if (!localStore.get('Bakeryswap_solana_wallet') || !localStore.get('Bakeryswap_solana_wallet')[account as string]) {
      setIsNeedConnect(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, isLogin])

  const cdRef: any = useRef(null)
  useEffect(() => {
    const updateCountDown = () => {
      if (startTime) {
        const leftTime = (startTime - Date.now()) / 1000
        setMigrateEndTime(leftTime)
        if (leftTime > 0) {
          const t = formatTime(leftTime)
          setLeftTime(t)
        } else {
          setLeftTime('')
          clearInterval(cdRef.current)
        }
      } else {
        clearInterval(cdRef.current)
      }
    }

    cdRef.current = setInterval(updateCountDown, 1000)

    return () => {
      clearInterval(cdRef.current)
      cdRef.current = null
    }
  }, [startTime])

  useEffect(() => {
    if (migrateEndTime < 0) {
      clearInterval(cdRef.current)
      cdRef.current = null
    }
  }, [migrateEndTime])

  const waitTransaction = useCallback(
    (txnHash: string | string[], options?: any) => {
      const interval = options && options.interval ? options.interval : 1000
      const transactionReceiptAsync = async function (
        txnHash: string,
        resolve: { (value: unknown): void; (arg0: TransactionReceipt): void },
        reject: { (reason?: any): void; (arg0: unknown): void }
      ) {
        const web3 = getWeb3(chainId as number)
        try {
          const receipt = await web3.eth.getTransactionReceipt(txnHash)
          const blockNumber = await web3.eth.getBlockNumber()
          if (!receipt || receipt.blockNumber >= blockNumber) {
            setTimeout(function () {
              transactionReceiptAsync(txnHash, resolve, reject)
            }, interval)
          } else {
            resolve(receipt)
          }
        } catch (e) {
          reject(e)
        }
      }

      // Resolve multiple transactions once
      if (Array.isArray(txnHash)) {
        const promises: any[] = []
        txnHash.forEach(function (oneTxHash) {
          promises.push(waitTransaction(oneTxHash, options))
        })
        return Promise.all(promises)
      } else {
        return new Promise(function (resolve, reject) {
          transactionReceiptAsync(txnHash, resolve, reject)
        })
      }
    },
    [chainId]
  )

  const switchNetwork = useCallback(async () => {
    const toChainId = BAKERY_HOST_ORG ? TO_MASTER_CHAINID : TO_DEV_CHAINID
    const ethereum: any = library?.provider
    if (chainId === toChainId[baseAsset.symbol.toLowerCase()]) return
    const web3 = getWeb3(toChainId[baseAsset.symbol.toLowerCase()])
    await ethereum
      .request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: web3.utils.toHex(toChainId[baseAsset.symbol.toLowerCase()]),
          },
        ],
      })
      .then(() => {
        // window.location.reload()
      })
      .catch(async (e: any) => {
        if ((e as any).code === 4902 || (e as any).code === -32603) {
          ethereum
            .request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: web3.utils.toHex(toChainId[baseAsset.symbol.toLowerCase()]),
                  chainName: NETWORK_LABELS[toChainId[baseAsset.symbol.toLowerCase()]],
                  nativeCurrency: {
                    name: 'ETH',
                    symbol: 'ETH',
                    decimals: 18,
                  },
                  rpcUrls: [NETWORKk_RPC_URL[toChainId[baseAsset.symbol.toLowerCase()]]],
                  blockExplorerUrls: [NETWORKk_URL_MAP[toChainId[baseAsset.symbol.toLowerCase()]]],
                },
              ],
            })
            .then(() => {})
            .catch((e: any) => {
              console.log(JSON.stringify(e))
            })
        } else if ((e as any).code === 4001) return
      })
  }, [baseAsset.symbol, chainId, library?.provider])

  useEffect(() => {
    const token = getAccountToken(account as string)
    switchNetwork()
    if (token) {
      setIsLogin(true)
      getUserInfoFn()
    } else {
      setIsLogin(false)
      updateSolanaPublicKey('')
      updateUserInfo({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId])

  useEffect(() => {
    StorageEmitter.on('storageSetItem', () => {
      const token = getAccountToken()
      if (token) {
        setIsLogin(true)
        getUserInfoFn()
        // handleFetchSolanaAddress()
      }
    })
    return () => {
      StorageEmitter.off('storageSetItem', () => {
        console.log('off')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const balance = useTokenBalance(baseAsset.symbol.toLowerCase())
  const isLess = useCallback(() => {
    if (baseAsset?.min && +value < baseAsset?.min) {
      return true
    } else {
      if (
        !value ||
        !balance ||
        new BigNumber(value).lt(0) ||
        new BigNumber(balance).lt(0) ||
        new BigNumber(value).comparedTo(new BigNumber(0)) === 0 ||
        new BigNumber(balance).comparedTo(new BigNumber(0)) === 0
      ) {
        return true
      } else {
        if (
          new BigNumber(allowanceLaunch).lt(0) ||
          new BigNumber(allowanceLaunch).lt(value) ||
          new BigNumber(balance).lt(value)
        ) {
          return true
        } else {
          return false
        }
      }
    }
  }, [baseAsset?.min, value, balance, allowanceLaunch])

  // useEffect(() => {
  //   if (account) {
  //     if (type === IDOTYPE.ONECAT && SUPPORTID.includes(chainId as number)) {
  //       let resetBaseAsset: any = baseAsset
  //       if (
  //         IDTOKEN['eth'].includes(chainId as number) &&
  //         !['eth', 'doggycoin'].includes(baseAsset.symbol.toLowerCase())
  //       ) {
  //         resetBaseAsset = options[0]
  //       }
  //       if (IDTOKEN['bakery'].includes(chainId as number) && !['bakery'].includes(baseAsset.symbol.toLowerCase())) {
  //         resetBaseAsset = options[2]
  //       }
  //       if (IDTOKEN['punkx'].includes(chainId as number) && !['punkx'].includes(baseAsset.symbol.toLowerCase())) {
  //         resetBaseAsset = options[3]
  //       }
  //       setBaseAsset(resetBaseAsset)
  //       callback(resetBaseAsset)
  //     }
  //     if (
  //       [IDOTYPE.BITLAND, IDOTYPE.BITCAT, IDOTYPE.BENDDAO, IDOTYPE.OPENSKY, IDOTYPE.BITCOINVM].includes(type) &&
  //       SUPPORTID.includes(chainId as number)
  //     ) {
  //       let resetBaseAsset: any = baseAsset
  //       if (IDTOKEN['onecat'].includes(chainId as number) && !['onecat'].includes(baseAsset.symbol.toLowerCase())) {
  //         resetBaseAsset = options[0]
  //       }
  //       if (IDTOKEN['bakery'].includes(chainId as number) && !['bakery'].includes(baseAsset.symbol.toLowerCase())) {
  //         resetBaseAsset = options[2]
  //       }
  //       setBaseAsset(resetBaseAsset)
  //       callback(resetBaseAsset)
  //     }
  //     if ([IDOTYPE.CATNFT].includes(type) && SUPPORTID.includes(chainId as number)) {
  //       let resetBaseAsset: any = baseAsset
  //       if (IDTOKEN['eth'].includes(chainId as number) && !['eth', 'onecat'].includes(baseAsset.symbol.toLowerCase())) {
  //         resetBaseAsset = options[0]
  //       }
  //       if (IDTOKEN['bakery'].includes(chainId as number) && !['bakery'].includes(baseAsset.symbol.toLowerCase())) {
  //         resetBaseAsset = options[2]
  //       }
  //       setBaseAsset(resetBaseAsset)
  //       callback(resetBaseAsset)
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [chainId, account, type, callback, options])

  const connect = useConnect()
  const handleConnectSolanaWallet = useCallback(
    async (wallet_name) => {
      const { okxwallet, phantom } = window
      if (!account) {
        toggleWalletModal()
        return
      }
      if (!isLogin) {
        loginFn()
        return
      }

      if (!okxwallet && wallet_name === 'okx') {
        addPopup({
          txn: {
            hash: '',
            success: false,
            summary: 'Please Install OKX Wallet',
          },
        })
        return
      }
      if (!phantom && wallet_name === 'phantom') {
        addPopup({
          txn: {
            hash: '',
            success: false,
            summary: 'Please Install Phantom Wallet',
          },
        })
        return
      }

      const [publicKey] = await connect(wallet_name, account)
      if (publicKey) {
        // const res = await bindSolanaAddress(chainId as number, {
        //   solana: publicKey.toString(),
        //   signature: Buffer.from(signature).toString('base64'),
        // })
        // if (res?.code !== 0) {
        //   addPopup({
        //     txn: {
        //       hash: '',
        //       success: false,
        //       summary: res.message,
        //     },
        //   })
        // } else {
        if (publicKey.toString() !== solAddress) {
          addPopup({
            txn: {
              hash: '',
              success: false,
              summary: 'Please connect to the address you have bound to claim.',
            },
          })
          return
        }
        updateUserInfo({ ...userInfo, solana: publicKey.toString() })
        setIsNeedConnect(false)
        // }
      }
      setIsOpen(false)
    },
    [account, addPopup, connect, isLogin, loginFn, solAddress, toggleWalletModal, updateUserInfo, userInfo]
  )

  // const handleRelieveBind = useCallback(async () => {
  //   const res = await relieveBindSolanaAddress(chainId as number, account as string)
  //   if (res.code === 0) {
  //     updateUserInfo({ ...userInfo, solana: '' })
  //   }
  // }, [account, chainId, updateUserInfo, userInfo])

  const [approvalLoading, setApprovalLoading] = useState(false)
  const handleApprove = useCallback(async () => {
    if (startTime && migrateEndTime >= 0) {
      return
    }
    if (!bindSolAddress) {
      addPopup({
        txn: {
          hash: '',
          success: false,
          summary: 'Please bind your solana address',
        },
      })
      return
    }
    setApprovalLoading(true)
    const network = window.location.href.includes('bakeryswap.org') ? ChainId.MAINNET : ChainId.SEPOLIA
    if (network !== chainId) {
      switchNetwork()
      return
    }

    const { ethereum } = window
    const web3 = getWeb3(chainId)
    const contract = new web3.eth.Contract(JSON.parse(JSON.stringify(TOKEN_ABI)), CONTRACT_ADDRESSES.dogex[chainId])

    const data = contract.methods
      .approve(CONTRACT_ADDRESSES.deposit[chainId], parseUnits(balance.toString()))
      .encodeABI()
    const transferParam = {
      to: CONTRACT_ADDRESSES.dogex[chainId],
      from: account,
      data,
      chainId: web3.utils.toHex(chainId),
    }

    ethereum
      ?.request({
        method: 'eth_sendTransaction',
        params: [transferParam],
      })
      .then(async (result: any) => {
        if (result) {
          const data: any = await waitTransaction(result)
          if (data.status) {
            setApprovalLoading(false)
            addPopup({
              txn: {
                hash: '',
                success: true,
                summary: 'Approve Success',
              },
            })
          }
        }
      })
      .catch(function (reason: any) {
        if (reason.code === 4001) {
          console.log('Failed to approve', reason.message)
        } else {
          console.log('Failed to approve', reason)
        }
        addPopup({
          txn: {
            hash: '',
            success: false,
            summary: 'Approve Failed',
          },
        })
        setApprovalLoading(false)
      })
  }, [account, addPopup, balance, chainId, migrateEndTime, bindSolAddress, startTime, switchNetwork, waitTransaction])

  const [requestedIdo, setRequestedIdo] = useState(false)
  const handleTransfer = useCallback(async () => {
    if (startTime && migrateEndTime >= 0) {
      return
    }
    if (!bindSolAddress) {
      addPopup({
        txn: {
          hash: '',
          success: false,
          summary: 'Please bind your solana address',
        },
      })
      return
    }
    setRequestedIdo(true)
    const network = window.location.href.includes('bakeryswap.org') ? ChainId.MAINNET : ChainId.SEPOLIA
    if (network !== chainId) {
      switchNetwork()
      return
    }

    const { ethereum } = window
    const web3 = getWeb3(chainId)
    const contract = new web3.eth.Contract(JSON.parse(JSON.stringify(MIGRATE_ABI)), CONTRACT_ADDRESSES.deposit[chainId])

    const data = contract.methods.deposit(CONTRACT_ADDRESSES.dogex[chainId], parseUnits(value.toString())).encodeABI()
    const transferParam = {
      to: CONTRACT_ADDRESSES.deposit[chainId],
      from: account,
      data,
      chainId: web3.utils.toHex(chainId),
    }

    ethereum
      ?.request({
        method: 'eth_sendTransaction',
        params: [transferParam],
      })
      .then(async (result: any) => {
        if (result) {
          const data: any = await waitTransaction(result)
          if (data.status) {
            handleFetchClaimAmount()
            setRequestedIdo(false)
            addPopup({
              txn: {
                hash: '',
                success: true,
                summary: 'Migrate Success',
              },
            })
          }
        }
      })
      .catch(function (reason: any) {
        if (reason.code === 4001) {
          console.log('Failed to migrate', reason.message)
        } else {
          console.log('Failed to migrate', reason)
        }
        addPopup({
          txn: {
            hash: '',
            success: false,
            summary: 'Migrate Failed',
          },
        })
        setRequestedIdo(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, addPopup, chainId, migrateEndTime, bindSolAddress, startTime, switchNetwork, value, waitTransaction])

  const [claimLoading, setClaimLoading] = useState(false)
  const handleClaim = useCallback(async () => {
    if (claimStartTime > Date.now()) {
      addPopup({
        txn: {
          hash: '',
          success: false,
          summary: 'The claim time has not arrived, please try again later',
        },
      })
      return
    }
    setClaimLoading(true)
    const network = 'mainnet'
    const publicKey = new PublicKey(solAddress)

    const solanaWallet = localStore.get('Bakeryswap_solana_wallet')[account as string]
    const wallet = solanaWallet === 'okx' ? window.okxwallet.solana : window.solana

    const connection = new Connection(network_url[network])
    const provider = new AnchorProvider(connection, wallet, AnchorProvider.defaultOptions())
    anchor.setProvider(provider)

    const res = await fetchClaimParams(chainId as number, account as string)
    if (res.code === 0) {
      const { claimId, signature, amount, distributor, recid } = res.data

      const program = new anchor.Program(IDL, programId[network])

      const [claimStatus] = await findClaimStatusKey(new BN(claimId), new PublicKey(distributor), programId[network])
      const [fromTokenAccount] = await getOrCreateAssociatedTokenAccount(
        connection,
        tokenAccount[network],
        new PublicKey(distributor),
        true
      )
      const [toTokenAccount, toTransaction] = await getOrCreateAssociatedTokenAccount(
        connection,
        tokenAccount[network],
        publicKey
      )

      const transaction = new Transaction()
      const claimTransaction = await program.methods
        .verification(new BN(claimId), new BN(amount), toArray(signature), recid)
        .accounts({
          distributor: new PublicKey(distributor),
          from: fromTokenAccount,
          toAta: toTokenAccount,
          payer: publicKey,
          claimStatus,
          systemProgram: SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
        })
        .transaction()

      if (toTransaction) {
        transaction.add(toTransaction)
      }

      transaction.add(claimTransaction)
      transaction.add(ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 200000 }))
      transaction.add(ComputeBudgetProgram.setComputeUnitLimit({ units: 500000 }))
      const { blockhash: recentBlockhash } = await connection.getLatestBlockhash()
      transaction.recentBlockhash = recentBlockhash
      transaction.feePayer = publicKey

      try {
        const tx = await provider.sendAndConfirm(transaction)
        setStatus(1)
        console.log('Your transaction signature', tx)
        addPopup({
          txn: {
            hash: '',
            success: true,
            summary: 'Claim Success',
          },
        })
      } catch (err) {
        console.error('Error processing transaction', err)
        addPopup({
          txn: {
            hash: '',
            success: false,
            summary: 'Claim Failed',
          },
        })
      } finally {
        setClaimLoading(false)
      }
    }
  }, [account, addPopup, chainId, claimStartTime, solAddress])

  // const handleConnectTonWallet = useCallback(() => {
  //   const connector = new window.TonConnectSDK.TonConnect()

  //   connector.restoreConnection()
  // }, [])

  const [status, setStatus] = useState(0)
  const [amount, setAmount] = useState('0')
  const handleFetchClaimParams = useCallback(async () => {
    const res = await fetchClaimParams(chainId as number, account as string)
    if (res.code === 0) {
      fetchClaimStatus(res.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, account])

  const fetchClaimStatus = useCallback(async (params) => {
    if (!params) return
    const { claimId, distributor } = params
    const network = 'mainnet'

    const [claimStatusPublicKey] = await findClaimStatusKey(
      new BN(claimId),
      new PublicKey(distributor),
      programId[network]
    )
    const connection = new Connection(network_url[network])
    const info: any = await connection.getAccountInfo(claimStatusPublicKey)
    if (info) {
      setStatus(1)
    } else {
      setStatus(0)
    }
  }, [])

  const handleFetchClaimAmount = useCallback(async () => {
    const res = await fetchClaimAmount(chainId as number, account as string)
    if (res.code === 0) {
      setAmount(res.data.amount)
      if (res.data.status) {
        handleFetchClaimParams()
      }
    } else {
      setAmount('0')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, account])

  useEffect(() => {
    if (!account) return
    handleFetchClaimAmount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, isLogin])

  const [isOpen, setIsOpen] = useState(false)
  const handleSubmit = useCallback(async () => {
    if (!account) {
      toggleWalletModal()
      return
    }
    if (!isLogin) {
      loginFn()
      return
    }
    if (!solAddress) {
      addPopup({
        txn: {
          hash: '',
          success: false,
          summary: 'Please bind your solana address',
        },
      })
      return
    }

    const res = await bindSolanaAddress(chainId as number, { solAddress })
    if (res.code === 0) {
      addPopup({
        txn: {
          hash: '',
          success: true,
          summary: 'Submit successfully',
        },
      })
      setBindSolAddress(solAddress)
      setIsNeedConnect(true)
    } else {
      addPopup({
        txn: {
          hash: '',
          success: false,
          summary: res.message,
        },
      })
    }
  }, [account, addPopup, chainId, isLogin, loginFn, solAddress, toggleWalletModal])

  return (
    <div>
      <Exchange>
        <BindWrapper>
          <BindTitle>Bind your solona address</BindTitle>
          <BindInputWrapper>
            <input
              value={solAddress}
              placeholder="Enter Solana Address"
              onChange={(e) => setSolAddress(e.target.value)}
            ></input>
            {!isMobile && (
              <SubmitBtn disabled={isMigrateEnd} onClick={handleSubmit}>
                {isMigrateEnd ? 'Finished' : bindSolAddress ? 'Update' : 'Submit'}
              </SubmitBtn>
            )}
          </BindInputWrapper>
          {isMobile && (
            <SubmitBtn disabled={isMigrateEnd} onClick={handleSubmit}>
              {isMigrateEnd ? 'Finished' : bindSolAddress ? 'Update' : 'Submit'}
            </SubmitBtn>
          )}
        </BindWrapper>
        <ExchangePets
          options={options}
          baseAsset={baseAsset}
          updateBaseAsset={updateBaseAsset}
          balance={balance}
          value={value}
          updateValue={updateValue}
          petShow={false}
          allowanceNum={allowanceLaunch}
          idoMax={true}
          idoMin={true}
        />
        {!account ? (
          <BtnConfirmed onClick={toggleWalletModal}>Connect Wallet</BtnConfirmed>
        ) : !isLogin ? (
          <BtnConfirmed onClick={loginFn}>Login</BtnConfirmed>
        ) : !new BigNumber(allowanceLaunch).toNumber() || new BigNumber(allowanceLaunch).lt(value) ? (
          <BtnConfirmed
            disabled={approvalLoading || isMigrateEnd}
            onClick={handleApprove}
            style={startTime && migrateEndTime >= 0 ? { cursor: 'auto', opacity: 0.4 } : {}}
          >
            {approvalLoading ? (
              <Row justify="center">
                Approving&nbsp;
                <Loader stroke="white" size="14px" />
              </Row>
            ) : startTime && migrateEndTime >= 0 ? (
              <>Countdown {leftTime}</>
            ) : isMigrateEnd ? (
              'Finished'
            ) : (
              'Approve'
            )}
          </BtnConfirmed>
        ) : (
          <BtnConfirmed
            onClick={handleTransfer}
            disabled={!(value && +value > 0) || balance.lt(value) || requestedIdo || isLess() || isMigrateEnd}
            style={startTime && migrateEndTime >= 0 ? { cursor: 'auto', opacity: 0.4 } : {}}
          >
            {startTime && migrateEndTime >= 0 ? (
              <>Countdown {leftTime}</>
            ) : isMigrateEnd ? (
              'Finished'
            ) : requestedIdo ? (
              <AutoRow gap="6px" justify="center">
                Pending <Loader stroke={theme.darkMode ? '#722F0D' : 'white'} />
              </AutoRow>
            ) : balance.lt(value) ? (
              'Insufficient Balance'
            ) : (
              'Migrate'
            )}
          </BtnConfirmed>
        )}
        {/* {isShowTip && nonWhitelistEndTime - Date.now() > 0 && (
          <Summary>
            <img src={TipIcon} />{' '}
            <span>
              {`This address is not on the Whitelist. The Non-WL Rounds start 1 hour later. Please be patient :)`}
            </span>
          </Summary>
        )} */}
        <Line />
        <StepWrapper>
          <Title>Claim your DogeX</Title>
          {/*   <Address>
            Hoz7XLXdEvT2nyfG3mVzt1GGzKxVEpAaxzzZJxzdCReM
            <div>
              {!copied ? <Copy onClick={() => setCopied('Hoz7XLXdEvT2nyfG3mVzt1GGzKxVEpAaxzzZJxzdCReM')} /> : 'copied'}
            </div>
          </Address>
          {/* <BindText>To receive $PUMP on BNB Chain for $IQ50, you need to bind your Solana Address</BindText> */}

          {/* <Row align="center" style={{ gridColumnGap: isMobile ? '9px' : '11px' }}>
              <WalletBtn disabled={okxLoading} onClick={() => handleConnectSolanaWallet('okx')}>
                <img className="okx" src="/images/launchpad/okx.png" />
              </WalletBtn>
              <WalletBtn disabled={phantomLoading} onClick={() => handleConnectSolanaWallet('phantom')}>
                <img className="phantom" src="/images/launchpad/phantom.png" />
              </WalletBtn>
            </Row> */}
          <RowBetween align="center">
            <ClaimNumber>
              {new BigNumber(amount).toFixed(3, BigNumber.ROUND_DOWN)}
              <span>DogeX</span>
            </ClaimNumber>
            {!solanaPublicKey || isNeedConnect ? (
              <WalletBtn
                onClick={() => {
                  if (!account) {
                    toggleWalletModal()
                    return
                  }
                  if (!isLogin) {
                    loginFn()
                    return
                  }
                  if (!bindSolAddress) {
                    addPopup({
                      txn: {
                        hash: '',
                        success: false,
                        summary: 'Please bind your solana address',
                      },
                    })
                    return
                  }
                  setIsOpen(true)
                }}
              >
                Connect
              </WalletBtn>
            ) : (
              <WalletBtn disabled={status === 1 || claimLoading} onClick={() => handleClaim()}>
                {status === 1 ? (
                  'Claimed'
                ) : claimLoading ? (
                  <>
                    Pending&nbsp;
                    <Loader stroke={theme.darkMode ? '#332a26' : 'white'} size="14px" />
                  </>
                ) : (
                  'Claim'
                )}
              </WalletBtn>
            )}
          </RowBetween>
        </StepWrapper>
        {/*<StepWrapper>
          <Title>3.Bridge $1CAT to 1CAT Chain</Title>
          <StakeWrapper>
            <NumericalInput fontSize={'15px'} placeholder="Enter quantity" value={value} onUserInput={updateValue} />
            <Max onClick={onMax}>MAX</Max>
            {!isMobile ? (
              !account ? (
                <BtnConfirmed onClick={toggleWalletModal}>Connect Wallet</BtnConfirmed>
              ) : !isLogin ? (
                <BtnConfirmed onClick={loginFn}>Login</BtnConfirmed>
              ) : new BigNumber(allowanceLaunch).gt(0) && !new BigNumber(allowanceLaunch).lt(value) ? (
                <BtnConfirmed
                  onClick={handleStake}
                  disabled={!(value && +value > 0) || balance.lt(value) || requestedIdo || isLess()}
                >
                  {requestedIdo ? (
                    <AutoRow gap="6px" justify="center">
                      Pending <Loader stroke="white" />
                    </AutoRow>
                  ) : balance.lt(value) ? (
                    'Insufficient Balance'
                  ) : (
                    'Stake'
                  )}
                </BtnConfirmed>
              ) : (
                <BtnConfirmed
                  onClick={() => handleApproveLaunch()}
                  disabled={requestedApprovalLaunch || balance.toNumber() <= 0}
                >
                  {requestedApprovalLaunch ? (
                    <AutoRow gap="6px" justify="center">
                      Approving <Loader stroke="white" />
                    </AutoRow>
                  ) : (
                    'Approve'
                  )}
                </BtnConfirmed>
              )
            ) : null}
          </StakeWrapper> */}
      </Exchange>
      <Des color={theme.bsText2}>{dataInfo.des}</Des>
      {/* {dataInfo.illustration && (
        <img style={{ width: '100%', height: 'auto', marginTop: '20px' }} src={dataInfo.illustration} alt="" />
      )} */}
      <Modal isOpen={isOpen} onDismiss={() => setIsOpen(false)}>
        <WalletList>
          <Top>
            Connect Wallet
            <CloseColor onClick={() => setIsOpen(false)} />
          </Top>
          <WalletWrapper onClick={() => handleConnectSolanaWallet('okx')}>
            <span>OKX Wallet</span>
            <img src="/images/okx.png" />
          </WalletWrapper>
          <WalletWrapper onClick={() => handleConnectSolanaWallet('phantom')}>
            <span>Phantom Wallet</span>
            <img src="/images/phantom.png" />
          </WalletWrapper>
        </WalletList>
      </Modal>
    </div>
  )
}
